import { buildRouteWithGraphPrefetch, PageConcurrentPrefetchProps } from "@emibee/lib-app-common";
import OrgIcon from "@mui/icons-material/CorporateFare";
import PersonIcon from "@mui/icons-material/Person";
import {
  ControlNames,
  controls,
  defaults,
  Domains,
  domainView,
  ViewNames,
  viewNamespace,
  views
} from "../../core/textResourceScopes";
import { OrganizationMH, OrganizationMHDataKit } from "../../data/organization";
import { UserInfo, UserInfoDataKit } from "../../data/user";
import { textResources } from "./textResources";
import { PersonalInfoSectionNames } from "./sections/definitions";

export enum PersonalInfoTab {
  account,
  business,
  settings
}

export interface PersonalInfoConcurrentProps extends PageConcurrentPrefetchProps<UserInfo> {
  accountId: string;
  initTab?: PersonalInfoTab;
  legacy?: boolean;
}
export interface PersonalInfoConcurrentProps2 extends PageConcurrentPrefetchProps<UserInfo> {
  accountId: string;
  initialSection?: PersonalInfoSectionNames;
  legacy?: boolean;
}

export const PersonalInfoRoute = buildRouteWithGraphPrefetch({
  path: "/persinfo",
  displayText: textResources.headerPersonal,
  icon: <PersonIcon />,
  auth: true,
  resources: {
    lazy: () => import("./PersonalInfoPage"),
    localeNamespaces: [domainView(Domains.account), views(ViewNames.personalInfo), defaults()],
    prefetchGraph: (args?: PersonalInfoConcurrentProps) => ({
      query: UserInfoDataKit.queries.getAccount,
      args: { id: args?.accountId }
    })
  },
  params: ["accountId", "initTab", "legacy"]
});
// export const PersonalInfoRoute2 = buildRouteWithGraphPrefetch({
//   path: "/persinfo2",
//   displayText: textResources.headerPersonal,
//   icon: <PersonIcon />,
//   auth: true,
//   resources: {
//     lazy: () => import("./PersonalInfoPage"),
//     localeNamespaces: [domainView(Domains.account), views(ViewNames.personalInfo), defaults()],
//     prefetchGraph: (args?: PersonalInfoConcurrentProps2) => ({
//       query: UserInfoDataKit.queries.getAccount,
//       args: { id: args?.accountId }
//     })
//   },
//   params: ["accountId", "initialSection", "legacy"]
// });

export enum OrganizationInfoTab {
  info,
  addresses,
  contacts,
  accounts,
  files,
  settings,
  blocklist
}

export interface OrganizationInfoConcurrentProps extends PageConcurrentPrefetchProps<OrganizationMH> {
  orgId: string;
  initTab?: OrganizationInfoTab;
}

export const OrganizationInfoRoute = buildRouteWithGraphPrefetch({
  path: "/orginfo",
  displayText: textResources.headerOrg,
  icon: <OrgIcon />,
  auth: true,
  resources: {
    lazy: () => import("./OrganizationInfoPage"),
    localeNamespaces: [
      controls(ControlNames.fileRef),
      views(ViewNames.personalInfo),
      domainView(Domains.account),
      domainView(Domains.organization),
      defaults(Domains.organization),
      defaults()
    ],
    prefetchGraph: (args?: OrganizationInfoConcurrentProps) => ({
      query: OrganizationMHDataKit.queries.getOrganization,
      args: { id: args?.orgId }
    })
  },
  params: ["orgId", "initTab"]
});
