import { Domains, buildTextResourcesMH } from "../core/textResourceScopes";

export const CommonTextResources = buildTextResourcesMH({
  scope: "Common",
  namespace: Domains.ui,
  resources: {
    routeHome: "Home",
    routeListText: "Dashboard",
    saveSuccess: "Data saved successfully",
    save: "Save",
    edit: "Edit",
    backButton: "Back",
    search: "Search",
    cancel: "Cancel",
    delete: "Delete",
    close: "Close",
    filter: "Filter",
    showMore: "Show more",
    showLess: "Show less",
    placeholderEditValue: "Please enter a value...",
    suffixDate: "Date",
    suffixTime: "Time",
    retry: "Retry",
    nextButton: "Next",
    prevButton: "Prev",
    suffixMonth: "Month",
    suffixYear: "Year",
    statusLoading: "loading...",

    showInFullView: "Show in Full View",

    removeAll: "remove all",
    revertAll: "revert all",

    sectionNavigation: "Quick Navigation",

    // errors
    tileValidationToastTitle: "Please check Your input",
    tileConnectingToastTitle: "Connection problems",
    tileConnectingToastText: "We can't connect to our server and trying to compensate.",
    tileNoConnectingToastText: "We could not reach our server.",
    tileServerErrorToastTitle: "Server problems",
    tileServerErrorToastText: "We could not process the request.",
    tileAuthorizationErrorToastTitle: "Authorization",
    tileAuthorizationErrorToastText: "You are not authorized to perform this action."
  }
});
