import { buildTextResources4Enum, enumNamespace, ResourceKey } from "@emibee/lib-app-common";
import { AuctionSoldState, AuctionState, AuctionType, SecurityAttributeScope, UserType } from "@mh/common";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Domains } from "../core/textResourceScopes";
import { IAuctionListItemUI } from "../data/auction";
import { IAuctionDetailsUI } from "../data/auction/AuctionDetails";
import { WidgetFilterDefinition } from "../exControls/widget/useWidgetFilter";

export function getSecurityAttributeScope(userType?: UserType) {
  if (userType === UserType.Dealer || userType === UserType.Admin || userType === UserType.System_Admin) {
    return SecurityAttributeScope.max;
  } else {
    return SecurityAttributeScope.min;
  }
}

export const isProspectingOrDutch = (auction: IAuctionDetailsUI | IAuctionListItemUI) => {
  return auction && (auction.auctionType === AuctionType.dutch || auction.auctionType === AuctionType.prospect);
};

export const hasAuctionEnded = (auction: IAuctionDetailsUI | IAuctionListItemUI) => {
  return auction && (auction.auctionEndTime < Date.now() || auction.state === AuctionState.ended);
};

export const isAuctionSold = (auction: IAuctionDetailsUI | IAuctionListItemUI) => {
  const startPrice = auction.startPrice || 0;
  return (
    auction &&
    hasAuctionEnded(auction) &&
    auction.lastBid &&
    (auction.lastBid.amount > startPrice ||
      auction.alternativePriceAccepted ||
      (isProspectingOrDutch(auction) && auction.isProspectDeal))
  );
};

export const AuctionFilterTextResources = buildTextResources4Enum<typeof AuctionFilterTypes>({
  scope: "Data",
  namespace: enumNamespace(Domains.auction, "AuctionType"),
  resources: {
    all: "All",
    pending: "Pending",
    ended: "Ended",
    open: "Open",
    deal: "Deal",
    noDeal: "NoDeal"
  }
});

export enum AuctionFilterTypes {
  all,
  pending,
  deal,
  noDeal,
  open,
  ended
  // prospectOpen,
  // prospectDeal,
  // prospectNoDeal
}

export const buildAuctionFilters = (enumValues: AuctionFilterTypes[]): WidgetFilterDefinition<AuctionFilterTypes>[] => {
  return enumValues.map(type => {
    let title: ResourceKey;
    let icon: React.ReactNode;

    switch (type) {
      case AuctionFilterTypes.all:
        title = AuctionFilterTextResources.all;
        icon = <FilterListOffIcon />;
        break;
      case AuctionFilterTypes.pending:
        title = AuctionFilterTextResources.pending;
        icon = <ScheduleIcon />;
        break;
      case AuctionFilterTypes.ended:
        title = AuctionFilterTextResources.ended;
        icon = <HourglassTopIcon />;
        break;
      case AuctionFilterTypes.open:
        title = AuctionFilterTextResources.open;
        icon = <RadioButtonUnchecked />;
        break;
      case AuctionFilterTypes.deal:
        title = AuctionFilterTextResources.deal;
        icon = <CheckCircleOutlineIcon />;
        break;
      case AuctionFilterTypes.noDeal:
        title = AuctionFilterTextResources.noDeal;
        icon = <CancelIcon />;
        break;
      default:
        throw new Error(`Unknown filter type: ${type}`);
    }
    return {
      type,
      title,
      icon
    };
  });
};

export function getAuctionSoldState(auction: IAuctionListItemUI) {
  // kann später einfach durch den AuctionState ersetzt werden (wenn legacy off ist)
  const hasAuctionEnded = auction.auctionEndTime < Date.now();

  const isBelowStartPrice =
    auction.lastBid &&
    auction.lastBid.amount !== null &&
    auction.lastBid.amount < (auction.startPrice || 0) &&
    auction.alternativePriceAccepted === null;

  if (!hasAuctionEnded) return false;
  const auctionSold = hasAuctionEnded && isAuctionSold(auction);

  if (
    (isProspectingOrDutch(auction) && auction.isProspectDeal === null) ||
    (!isProspectingOrDutch(auction) && isBelowStartPrice)
  ) {
    return AuctionSoldState.open;
  } else if (
    (isProspectingOrDutch(auction) && auction.isProspectDeal === true) ||
    (!isProspectingOrDutch(auction) && auctionSold)
  ) {
    return AuctionSoldState.sold;
  } else if (
    (isProspectingOrDutch(auction) && auction.isProspectDeal === false) ||
    (!isProspectingOrDutch(auction) && !auctionSold && !isBelowStartPrice)
  ) {
    return AuctionSoldState.notSold;
  }
}

export function filterAuctions(auction: IAuctionListItemUI, type: AuctionFilterTypes[]) {
  const hasAuctionEnded = auction.auctionEndTime < Date.now();
  const isPending = type?.includes(AuctionFilterTypes.pending) && !hasAuctionEnded;
  const isEnded = type?.includes(AuctionFilterTypes.ended) && hasAuctionEnded;

  const isOpen = type?.includes(AuctionFilterTypes.open) && getAuctionSoldState(auction) === AuctionSoldState.open;
  const isDeal = type?.includes(AuctionFilterTypes.deal) && getAuctionSoldState(auction) === AuctionSoldState.sold;
  const isNoDeal =
    type?.includes(AuctionFilterTypes.noDeal) && getAuctionSoldState(auction) === AuctionSoldState.notSold;

  // return (!type || isPending || isEnded || isOpen || isDeal || auctionSold || isNoDeal || !auctionSold).valueOf();

  return (
    !type ||
    (type.includes(AuctionFilterTypes.pending) && isPending) ||
    (type.includes(AuctionFilterTypes.ended) && isEnded) ||
    isOpen ||
    isDeal ||
    isNoDeal
  ).valueOf();
}
